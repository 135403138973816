export const CHANGE_CHAIN_BEGIN = "CHANGE_CHAIN_BEGIN"
export const CHANGE_CHAIN_SUCCESS = "CHANGE_CHAIN_SUCCESS"
export const CHANGE_CHAIN_FAILURE = "CHANGE_CHAIN_FAILURE"

export const CONNECT_ACCOUNT_BEGIN = "CONNECT_ACCOUNT_BEGIN"
export const CONNECT_ACCOUNT_SUCCESS = "CONNECT_ACCOUNT_SUCCESS"
export const ACCOUNT_CHANGE = "ACCOUNT_CHANGE"

export const DISCONNECT_ACCOUNT_BEGIN = "DISCONNECT_ACCOUNT_BEGIN"
export const DISCONNECT_ACCOUNT_SUCCESS = "DISCONNECT_ACCOUNT_SUCCESS"

