export const EXCHANGE_BEGIN = "EXCHANGE_BEGIN";

export const DEPOSIT_SUCCESS = "DEPOSIT_SUCCESS";
export const DEPOSIT_FAILURE = "DEPOSIT_FAILURE";

export const WITHDRAW_SUCCESS = "WITHDRAW_SUCCESS";
export const WITHDRAW_FAILURE = "WITHDRAW_FAILURE";

export const FETCH_TOKEN_BALANCE_BEGIN = "FETCH_TOKEN_BALANCE_BEGIN";
export const FETCH_TOKEN_BALANCE_SUCCESS = "FETCH_TOKEN_BALANCE_SUCCESS";
export const FETCH_TOKEN_BALANCE_FAILURE = "FETCH_TOKEN_BALANCE_FAILURE";

export const FETCH_ALLOWANCE_BEGIN = "FETCH_ALLOWANCE_BEGIN"
export const FETCH_ALLOWANCE_SUCCESS = "FETCH_ALLOWANCE_SUCCESS"
export const FETCH_ALLOWANCE_FAILED = "FETCH_ALLOWANCE_FAILED"

export const APPROVE_BEGIN = 'APPROVE_BEGIN'
export const APPROVE_SUCCESS = "APPROVE_SUCCESS"
export const APPROVE_FAILED = "APPROVE_FAILED"

export const TRANSFER_BEGIN = "TRANSFER_BEGIN"
export const TRANSFER_SUCCESS = "TRANSFER_SUCCESS"
export const TRANSFER_FAILED = "TRANSFER_FAILED"